const simplifyUrl = url => url?.replace(/^https?:\/\//, "")

/**
 * Encodes the passed data into URL format.
 * @param {*} data 
 * @returns 
 */
const encodeUrl = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
  
export {
    simplifyUrl,
    encodeUrl
}