import { useState } from 'react'
import { encodeUrl } from '../utils'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const useForm = (defaultState) => {
  const [state, setState] = useState(defaultState)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const { executeRecaptcha } = useGoogleReCaptcha()
  const handleSubmit = async e => {
    e.preventDefault()
    setError(false)
    setSuccess(false)
    setLoading(true)

    // if reCAPTCHA is not ready to be used
    if (!executeRecaptcha) {
      setLoading(false)
      setError(true)
      return
    }

    const formName = e.target.getAttribute('name') ?? 'form name not provided'
    // generate a token associated with the interaction of the form
    const token = await executeRecaptcha(formName)

    // determine humanity by using that token in a protected, server-side function
    let reCaptchaStatus = await fetch('/.netlify/functions/verify-recaptcha', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token: token,
      }),
    })

    // only parse response if the request was successful
    if (reCaptchaStatus.status == 200) {
      reCaptchaStatus = await reCaptchaStatus.json()
    }

    if (reCaptchaStatus?.isHuman) {
      // submit to Netlify Forms
      const submission = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encodeUrl({
          'form-name': formName,
          ...state,
        }),
      })

      if (submission.status == 200) {
        setLoading(false)
        setState(defaultState)
        setSuccess(true)
      } else {
        // request to Netlify Forms failed
        setLoading(false)
        setError(true) 
      }
      return
    }
    
    // the interaction was not human
    setLoading(false)
    setError(true)
  }

  return {
    state,
    setState,
    loading,
    error,
    success,
    handleChange,
    handleSubmit,
  }
}

export default useForm