import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PrismicRichText, PrismicLink } from "@prismicio/react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"

import FlexibleContent from "../components/FlexibleContent/FlexibleContent"
import { simplifyUrl } from "../utils"
import ContactForm from "../components/ContactForm"


const ContactPage = ({ data }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}>
      <ContactPageWithoutReCaptcha data={data}></ContactPageWithoutReCaptcha>
    </GoogleReCaptchaProvider>
  )
}

const ContactPageWithoutReCaptcha = ({ data }) => {
  const {
    header_image,
    header_title,
    form_section_title,
    form_section_content,
    form_section_contacts,
    enquiry_section_title,
    enquiry_section,
    flexible_content,
    meta_title,
    meta_description,
  } = data.contact.fields

  return (
    <Layout>
      <Seo title={meta_title || header_title} description={meta_description} />
      <section className="relative h-50 lg:h-75 xl:h-100">
        {header_image && (
          <GatsbyImage
            className="h-full w-full object-cover"
            image={getImage(header_image.gatsbyImageData)}
            alt={header_image.alt || ""}
          />
        )}
        {header_title && (
          <div className="absolute bottom-0 left-0 m-gutter">
            <h1 className="max-w-4xl text-4xl font-bold uppercase tracking-wide text-cloud-white lg:text-6xl xl:text-7xl">
              {header_title}
            </h1>
          </div>
        )}
      </section>
      <section className="section bg-sky-blue text-cloud-white">
        {form_section_title && (
          <div className="grid grid-cols-12 lg:gap-x-10">
            <div className="col-start-1 col-end-13 mb-12 lg:mb-14">
              <h2 className="text-3xl font-bold uppercase tracking-wide">
                {form_section_title}
              </h2>
            </div>
          </div>
        )}
        <div className="kmd-gap grid grid-cols-12">
          <div className="col-start-1 col-end-13 lg:col-end-6">
            <div className="rte mb-8">
              <PrismicRichText field={form_section_content.richText} />
            </div>
            {form_section_contacts && (
              <div className="space-y-7">
                {form_section_contacts.map((contact, index) => {
                  const {
                    title,
                    phone_number,
                    email_address,
                    website_text,
                    website_link,
                  } = contact

                  if (
                    !title ||
                    !(phone_number || email_address || website_link)
                  )
                    return null

                  return (
                    <div key={index}>
                      <h3 className="mb-3 text-xl font-semibold">{title}</h3>
                      <ul className="space-y-1">
                        {phone_number && (
                          <li>
                            <span className="inline-block w-16 font-semibold">
                              Phone:{" "}
                            </span>
                            <a
                              className="link link--white"
                              href={`tel:${phone_number}`}
                            >
                              {phone_number}
                            </a>
                          </li>
                        )}
                        {email_address && (
                          <li>
                            <span className="inline-block w-16 font-semibold">
                              Email:{" "}
                            </span>
                            <a
                              className="link link--white"
                              href={`mailto:${email_address}`}
                            >
                              {email_address}
                            </a>
                          </li>
                        )}
                        {website_link && website_link.url && (
                          <li>
                            <span className="inline-block w-16 font-semibold">
                              Web:{" "}
                            </span>
                            <PrismicLink
                              className="link link--white inline-flex items-center"
                              field={website_link}
                            >
                              {website_text || simplifyUrl(website_link.url)}
                              <svg
                                className="ml-1 mb-2 h-3 w-3"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                ></path>
                              </svg>
                            </PrismicLink>
                          </li>
                        )}
                      </ul>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          <div className="col-start-1 col-end-13 lg:col-start-7 lg:col-end-12 xl:col-end-11">
            <ContactForm />
          </div>
        </div>
      </section>
      <section className="section">
        {enquiry_section_title && (
          <div className="grid grid-cols-12 lg:gap-x-10">
            <div className="col-start-1 col-end-13 mb-12 lg:mb-14">
              <h2 className="text-3xl font-bold uppercase tracking-wide">
                {enquiry_section_title}
              </h2>
            </div>
          </div>
        )}
        {enquiry_section && (
          <div className="space-y-10">
            {enquiry_section.map((slice, index) => {
              const {
                fields: { title },
                contacts,
              } = slice

              if (!title || !contacts.length) return null

              return (
                <div className="" key={index}>
                  <h3 className="mb-4 text-2xl font-bold tracking-wide">
                    {title}
                  </h3>
                  <div className="kmd-gap grid grid-cols-12">
                    {contacts.map((contact, index) => {
                      const {
                        title,
                        name,
                        address,
                        phone_number,
                        fax_number,
                        email_address,
                        website_link,
                      } = contact

                      if (
                        !title ||
                        !(
                          address ||
                          phone_number ||
                          fax_number ||
                          email_address ||
                          website_link
                        )
                      )
                        return null

                      return (
                        <div className="col-span-12 md:col-span-4" key={index}>
                          <h4 className="mb-4 text-lg font-bold">{title}</h4>
                          {name && <h5 className="mb-2 font-bold">{name}</h5>}
                          <ul className="space-y-1">
                            {address && (
                              <li className="mb-4">
                                <span className="block font-semibold">
                                  Address:
                                </span>
                                {address}
                              </li>
                            )}
                            {phone_number && (
                              <li>
                                <span className="inline-block w-16 font-semibold">
                                  Phone:
                                </span>
                                <a
                                  className="link"
                                  href={`tel:${phone_number}`}
                                >
                                  {phone_number}
                                </a>
                              </li>
                            )}
                            {fax_number && (
                              <li>
                                <span className="inline-block w-16 font-semibold">
                                  Fax:
                                </span>
                                <span>{fax_number}</span>
                              </li>
                            )}
                            {email_address && (
                              <li>
                                <span className="inline-block w-16 font-semibold">
                                  Email:
                                </span>
                                <a
                                  className="link"
                                  href={`mailto:${email_address}`}
                                >
                                  {email_address}
                                </a>
                              </li>
                            )}
                            {website_link && website_link.url && (
                              <li>
                                <span className="inline-block w-16 font-semibold">
                                  Web:
                                </span>
                                <PrismicLink
                                  className="link"
                                  field={website_link}
                                >
                                  {simplifyUrl(website_link.url)}
                                </PrismicLink>
                              </li>
                            )}
                          </ul>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </section>

      {flexible_content &&
        flexible_content.map(slice => (
          <FlexibleContent key={slice.id} {...slice} />
        ))}
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query ContactQuery {
    contact: prismicContact {
      fields: data {
        header_image {
          gatsbyImageData
          alt
        }
        header_title
        form_section_title
        form_section_content {
          richText
        }
        form_section_contacts {
          title
          phone_number
          email_address
          website_text
          website_link {
            ...linkFragment
          }
        }
        enquiry_section_title
        enquiry_section {
          ... on PrismicContactDataEnquirySectionContactGroup {
            fields: primary {
              title
            }
            contacts: items {
              title
              name
              address
              phone_number
              fax_number
              email_address
              website_link {
                ...linkFragment
              }
            }
          }
        }
        flexible_content {
          ... on PrismicContactDataFlexibleContentBrandsSection {
            id
            slice_type
            fields: primary {
              layout
              title
              content {
                richText
              }
              cta_text
              cta_link {
                ...linkFragment
              }
            }
            brands: items {
              brand {
                document {
                  ...brandsFragment
                }
              }
            }
          }
          ... on PrismicContactDataFlexibleContentCtaCardsSection {
            id
            slice_type
            cta_cards: items {
              cta_card {
                document {
                  ...ctaCardsFragment
                }
              }
            }
          }
        }
        meta_title
        meta_description
      }
    }
  }
`
