import React from "react"
import useForm from "../hooks/useForm"
import CircleSpinner from "./SVG/CircleSpinner"

const ContactForm = () => {
  const defaultState = {
    name: "",
    email: "",
    subject: "",
    country: "",
    message: "",
  }

  const {
    state,
    loading,
    error,
    success,
    handleChange,
    handleSubmit,
  } = useForm(defaultState)

  return (
    <div>
      <form
        data-netlify="true"
        name="contact"
        hidden={success}
        onSubmit={handleSubmit}
      >
        {/* required by Netlify Forms to detect JS-rendered forms */}
        <input type="hidden" name="form-name" value="contact" />
        <ul className="form">
          <li>
            <label className="form-label" htmlFor="name">
              Name <span className="text-coral-pink">*</span>
            </label>
            <input
              id="name"
              className="form-input"
              name="name"
              value={state.name}
              onChange={handleChange}
              required
            />
          </li>
          <li>
            <label className="form-label" htmlFor="email">
              Email <span className="text-coral-pink">*</span>
            </label>
            <input
              id="email"
              type="email"
              className="form-input"
              name="email"
              value={state.email}
              onChange={handleChange}
              required
            />
          </li>
          <li>
            <label className="form-label" htmlFor="subject">
              Subject <span className="text-coral-pink">*</span>
            </label>
            <input
              id="subject"
              className="form-input"
              name="subject"
              value={state.subject}
              onChange={handleChange}
              required
            />
          </li>
          <li>
            <label className="form-label" htmlFor="country">
              Country <span className="text-coral-pink">*</span>
            </label>
            <input
              id="country"
              className="form-input"
              name="country"
              value={state.country}
              onChange={handleChange}
              required
            />
          </li>
          <li>
            <label className="form-label" htmlFor="message">
              Message <span className="text-coral-pink">*</span>
            </label>
            <textarea
              id="message"
              className="form-input h-32"
              name="message"
              value={state.message}
              onChange={handleChange}
              required
            />
          </li>
          <li>
            <button
              className="button button--white w-full text-sky-blue"
              disabled={loading}>
              {
                loading
                  ? <CircleSpinner />
                  : 'Submit'
              }
            </button>
          </li>
        </ul>
      </form>

      {/* success and error messages */}
      <div className="rte">
        <p hidden={!success}>
          Thank you for getting in touch. We'll aim to respond to your enquiry
          as soon as possible.
        </p>

        <p hidden={!error} className="mt-5">
          There was an error submitting the form, please try again.
        </p>
      </div>
    </div>
  )
}

export default ContactForm
